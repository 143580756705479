require('./bootstrap');
$.fn.processItemErrors = function (error, itemName) {
    if (error.response.data.errors) {
        $(this).removeErrorMessages();
        let errors = [];
        $.each(error.response.data.errors, function (key, value) {
            let item = ('modal' == itemName) ? $('#modal').find('[name="' + key + '"]') : $('#item_' + itemName).find('[name="' + key + '"]')

            if (item.length) {
                item.after('<div class="invalid-feedback" style="display:block!important">' + value[0] + '</div>');
                item.addClass('is-invalid');
            } else {
                errors.push(value[0]);
            }
        });
        if (errors.length > 0) {
            $(document).toastMessage(errors.join('<br/>'));
        }
    }
};
$.fn.removeErrorMessages = function () {
    $('.error-message').remove();
    $('.is-invalid').removeClass('is-invalid');
    $('.invalid-feedback').remove();
};
$.fn.toastMessage = function (message) {
    $('.toast-container').append(
        '<div class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-delay="10000">\n' +
        '   <div class="toast-body">\n' +
        '       <div class="">\n' +
        '           <strong class="mr-auto">EGC</strong>'+
        '           <button type="button" class=" close" data-dismiss="toast" aria-label="Close">\n' +
        '               <span aria-hidden="true">&times;</span>\n' +
        '           </button>\n' +
        '       </div>\n' +
        '       <div class="text">\n' +
        '           ' + message +
        '       </div>\n' +
        '   </div>\n' +
        '</div>'
    );
    $('.toast').last().toast('show');
};
$.fn.loadModalListener = function() {
    $(document).on('click', '._load-modal', function(event) {
        event.preventDefault();
        let url = $(this).data('url');

        // Check if save-position is set to 1, then save the current scroll position
        if ($(this).data('save-position') === 1) {
            localStorage.setItem('lastScrollPosition', $(window).scrollTop());
        }

        axios.get(url)
            .then(function(response) {
                $('#modal').html(response.data).modal('show');
            })
            .catch(function(error) {
                $(document).toastMessage("Er is iets misgegaan! Probeer het later nog eens.");
            });
    });

    // When the page loads, check if there is a saved scroll position and scroll to it
    $(document).ready(function() {
        let lastScrollPosition = localStorage.getItem('lastScrollPosition');
        if (lastScrollPosition) {
            $(window).scrollTop(lastScrollPosition);
            localStorage.removeItem('lastScrollPosition'); // Remove the stored position after scrolling
        }
    });
};


$.fn.submitModalFormListener = function (){
    $(document).on('submit', '._modal-form', function (event) {
        event.preventDefault()
        let form = $(this);
        let buttons = form.find('[type="submit"]');

        buttons.prop('disabled', true);
        buttons.find('._loading').show()
        buttons.find('.icon').hide()

        let url = form.attr('action');
        let formData = new FormData(form[0]);
        axios.post(url, formData)
            .then(function (response) {
                $('#modal').modal('hide')
                window.location.reload()
            })
            .catch(function (error) {
                $(document).processItemErrors(error, 'modal');
            })
            .finally(function() {
                buttons.prop('disabled', false);
                buttons.find('._loading').hide()
                buttons.find('.icon').show()
            })
    })
};
$.fn.deleteListener = function (){
    $(document).on('click', '._trigger-delete', function (event) {
        event.preventDefault();
        let element = $(this);
        let url = element.data('url');
        let redirect = element.data('redirect') ?? null;
        url = (url.indexOf('?') >= 0) ? url+"&_method=delete" : url+"?_method=delete";
        let c = element.hasClass('_confirm') ? confirm("Weet u het zeker? Deze actie kan niet ongedaan gemaakt worden.") : true;
        if (c) {
            axios.post(url)
                .then(function () {
                    window.location.href = redirect ?? window.location.href;
                })
                .catch(function () {
                    $(document).toastMessage("Er is iets misgegaan! Probeer het later nog eens.");
                })
        }

    })
};
$.fn.copyToClipboard = function (text){
    let temp = $('<input>');
    $('body').append(temp);
    temp.val(text).select();
    document.execCommand("copy");
    temp.remove();
};
$.fn.selectpicker.Constructor.DEFAULTS.noneSelectedText = 'Maak een keuze';
//Default settings for datatables
$.extend(true, $.fn.dataTable.defaults, {
    "bStateSave": true,
    "language": {
        "sProcessing": "<span class='loading'></span>",
        "sLengthMenu": "_MENU_ resultaten weergeven",
        "sZeroRecords": "Geen resultaten gevonden",
        "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
        "sInfoEmpty": "Geen resultaten om weer te geven",
        "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
        "sInfoPostFix": "",
        "sSearch": "Zoeken:",
        "sEmptyTable": "Geen resultaten aanwezig in de tabel",
        "sInfoThousands": ".",
        "sLoadingRecords": "Een moment geduld aub - bezig met laden...",
        "oPaginate": {
            "sFirst": "Eerste",
            "sLast": "Laatste",
            "sNext": "Volgende",
            "sPrevious": "Vorige"
        },
        "oAria": {
            "sSortAscending": ": activeer om kolom oplopend te sorteren",
            "sSortDescending": ": activeer om kolom aflopend te sorteren"
        },
        "buttons": {
            "colvis": "Kolommen",
        }
    },
});
